.brands-section {
	.frame {
		position: relative;
		float: left;
		width: 100%;
		padding: 60px 20px;
		background-color: #fff;
		.canvas {
			text-align: center;
		}
	}
	.box {
		position: relative;
		display: inline-block;
		text-align: center;
		width: 200px;
		height: 100px;

		img {position: relative;
			top: 50px;
			width: 100%;
			max-width: 150px;
		}
	}
}

.news-letter {
		.frame {
		width: 100%;
		padding: 40px 0px;
		text-align: center;
		background-color: $green;
		overflow-x: hidden;


		h4 {
			display: inline-block;
		  	font-size: 22px;
		  	color: rgb(255, 255, 255);
		}
		form {
			display: inline-block;
			width: 400px;
			input {
				display: block;
				float: left;
				position: relative;
				top: 10px;
				padding-left: 15px;
				margin-left: 20px;
				border: none;
				width: 200px;
				height: 40px;
			}
			.button {
				display: block;
				float: left;
			  	font-size: 16px;
			  	color: rgb(255, 255, 255);
			  	border: none;
			  	height: 40px;
			  	width: 150px;
			  	padding-right: 20px;
			  	background-color: $green;
			}
		}
	}
}

.footer {

	.frame {
		float: left;
		width: 100%;
		padding: 60px 40px;
		background-color: $darkgreen;

		.col-3 {
			h3 {
				color: #fff;
			}
			p {
				color: #fff;
				line-height: 20px;
			}
			a {
				color: #fff;
				line-height: 25px;
			}
			a:hover {
				opacity: 0.6;
			}
		}
	}
}

.credits {

	.container {
		height: 100px;
		padding: 0 40px;
		border-top: 1px solid rgb(255, 255, 255);
		background-color: $darkgreen;

		p {
			padding-top: 20px;

			color: #fff;
		}
		.logo-bottom {
			display: block;
			position: absolute;
			left: 50%;
			top: 20px;
			@include transform(translate(-50%,0%));
			width: 66px;
			height: 35px;
			background-image: url(../images/icon-crown.svg);
		}
	}
}


/* ==========================================================================
   media styles
   ========================================================================== */


@media screen and (max-width: 959px) {

}

@media screen and (max-width: 767px) {
	.footer {
			.frame {
				padding: 60px 20px;
				.col-3 {
					width: 50%;
					margin-bottom: 40px;
					h3 {
						color: #fff;
					}
					p {
						color: #fff;
						line-height: 20px;
					}
					a {
						color: #fff;
						line-height: 25px;
					}
					a:hover {
						opacity: 0.6;
					}
				}
			}
		}
}

@media screen and (max-width: 479px) {
	.footer {
			.frame {
				padding: 60px 20px;
				.col-3 {
					width: 100%;
					margin-bottom: 40px;
					h3 {
						color: #fff;
					}
					p {
						color: #fff;
						line-height: 20px;
					}
					a {
						color: #fff;
						line-height: 25px;
					}
					a:hover {
						opacity: 0.6;
					}
				}
			}
		}
	.credits {
		.container {
			padding: 0 20px;
			.logo-bottom {
				display: block;
				position: absolute;
				left: inherit;
				right: 0;
				top: 20px;
				@include transform(translate(-50%,0%));
				width: 66px;
				height: 35px;
			}
		}
	}
	.news-letter .frame form {
		.button {
			float: inherit;
			position: relative;
			left: 50%;
			top: 20px;
			margin: 0;
			@include transform(translate(-50%,0));
		}
		input {
			width: 85%;
		}


	}
}




