.text-section {
	z-index: 99;
	.textbox {
		float: left;
		width: 100%;
		background-color: $green;
		z-index: 999;
		.inner {
			margin: 0 auto;
			width: 600px;
			padding: 50px 0;
			z-index: 999;
			h2 {
				color: #fff;
				text-align: center;
  				font-size: 28px;
  				font-family: 'Open Sans', serif;
  				font-weight: 300;
				text-transform: uppercase;
			}
			p {
				color: #fff;
				text-align: center;
  				font-size: 18px;

  				line-height: 22px;

			}
		}
		.btn-purple {
			display: block;
			position: absolute;
			right: 130px;
			top: 100px;
			width: 150px;
			height: 150px;
			border-radius: 150px;
			background-color: $orange;
			padding-top: 38px;
			padding-left: 0px;
			font-family: 'Open Sans', serif;
			font-weight: 600;
			color: #fff;
			line-height: 20px;
			text-align: center;
  			font-size: 20px;
  			z-index: 999;
		}
	}
}

.background-block {
	position: absolute;
	top: 1150px;
	width: 100%;
	height: 750px;
	z-index: 9;
	background-color: #e8f1ef;
}


.flex-content {
	overflow-x: hidden;
	z-index: 9;
	.inner {
		float: left;
		width: 100%;
		background-color: #fff;
	}
	h2 {
		font-size: 20px;

		color: $darkgreen;
    }
    p {
		font-size: 16px;

		color: $darkgreen;
		line-height: 22px;
		padding-bottom: 30px;
    }
    a {
    	padding: 10px 25px;
    	background-color: $green;
  		font-size: 16px;
  		color: rgb(255, 255, 255);
  		span {
  				display: inline-block;
  				margin-left: 5px;
  				width: 6px;
  				height: 9px;
  				background-image: url(../images/icon-crown.svg);
  				background-size: cover;
  				background-position: center center;
  			}
    }
	.text-section {
		margin: 0 auto !important;
	    width: 70%;
	    padding: 40px 0 10px 0;
	    text-align: center;
	}
	.video-section {
	    margin: 0 auto !important;
	    width: 50%;
	    margin: 60px 0;
	}
	.bg {
		float: left;
		width: 100%;
	    background-color: $lightgreen;
	}
	.frame-photo-right {
		width: 70%;
		margin: 0 auto;
		.text {
			float: left;
			width: 60%;
			text-align: right;
			padding-right: 40px;
			margin: 20px 0;
		}
		.photo {
			float: left;
			width: 30%;
			padding-bottom: 20%;
			background-size: cover;
			background-position: center center;
			margin: 20px 0;
		}
	}
	.frame-photo-left {
		width: 70%;
		margin: 0 auto;
		.text {
			float: right;
			width: 60%;
			text-align: left;
			padding-left: 40px;
			margin: 20px 0;
		}
		.photo {
			float: right;
			width: 30%;
			padding-bottom: 20%;
			background-size: cover;
			background-position: center center;
			margin: 20px 0;
		}
	}
	.image-section {
		position: relative;
		width: 100%;
		float: left;
		background-image: url(../images/visual-dotted-line.png);
		background-size: 100% 350px;
		background-repeat: no-repeat;
		background-position: center center;
		img {
			display: inline-block;
			position: relative;
			left: 50%;
			@include transform(translate(-50%,0));
			width: 50%;
			margin: 40px 20px;
		}
	}
	.media-section {
		width: 100%;
		float: left;
		padding: 40px;
		background-color: #fff;
		.news {
			float: left;
			width: 50%;
			height: 300px;
			background-size: cover;
			background-position: center center;
			h2 {
				padding: 20px;
	  			font-size: 26px;
				color: rgb(255, 255, 255);
			}
			a {
				padding: 10px 20px;
				margin-left: 20px;
				background-color: $green;
				border-radius: 2px;
	  			font-size: 16px;
	  			color: rgb(255, 255, 255);
	  			span {
	  				display: inline-block;
	  				margin-left: 5px;
	  				width: 6px;
	  				height: 9px;
	  				background-image: url(../images/icon-crown.svg);
	  				background-size: cover;
	  				background-position: center center;
	  			}
			}
		}
		.text {
			position: relative;
			float: left;
			width: 50%;
			height: 300px;
			background-color: $orange;
			.crown {
				position: absolute;
				bottom: 0;
				right: -65px;
				width: 130px;
				height: 70px;
				background-image: url(../images/icon-crown.svg);
				background-size: cover;
			}
			h3 {
  				font-size: 22px;
  				color: rgb(255, 255, 255);
  				font-style: italic;
  				text-align: center;
  				padding: 50px 50px 40px 50px;

			}
			p {
				font-size: 16px;
  				color: rgb(255, 255, 255);
  				text-align: center;
  				font-style: italic;
			}
		}
		.portrait {
			float: left;
			width: 25%;
			height: 300px;
			background-size: cover;
			background-position: center center;
		}
		.landscape {
			float: left;
			width: 50%;
			height: 300px;
			background-size: cover;
			background-position: center center;
		}
	}
}


/* ==========================================================================
   media styles
   ========================================================================== */


@media screen and (max-width: 1100px) {
	body.page-id-7 .text-section {
		.textbox {
			float: left;
			width: 100%;
			background-color: $green;
			.inner {
				margin: 0 auto;
				width: 100%;
				padding: 50px 150px 50px 100px;
			}
			.btn-purple {
				right: 0px;
				top: 100px;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	body.page-id-7 .text-section {
		.textbox {
			float: left;
			width: 100%;
			background-color: $green;
			.inner {
				margin: 0 auto;
				width: 100%;
				padding: 50px 20px 100px 20px;
			}
			.btn-purple {
				right: 0px;
				top: 200px;
			}
		}
	}
	.flex-content {
		.text-section {
		    width: 100%;
		    padding: 40px 20px 10px 20px;
		    text-align: center;
		}
		.video-section {
		    width: 100%;
		    margin: 60px 0;
		    padding: 0 10px;
		}
		.frame-photo-right {
			width: 100%;
			margin: 0 auto;
			padding: 0 10px;
			.text {
				float: left;
				width: 100%;
				text-align: left;
				padding: 0px 20px;
				margin: 0px;
			}
			.photo {
				float: inherit;
				display: inline-block;
				text-align: center;
				width: 100%;
				height: 150px;
				padding-bottom: 0px;
				margin: 0;
				margin-bottom: 40px;
			}
		}
		.frame-photo-left {
			width: 100%;
			margin: 0 auto;
			padding: 0 0px;
			.text {
				float: left;
				width: 100%;
				text-align: left;
				padding: 0 10px;
				margin: 0px;
			}
			.photo {
				float: inherit;
				display: inline-block;
				text-align: center;
				width: 100%;
				height: 150px;
				padding-bottom: 0px;
				margin: 0;
			}
		}
		.image-section {
			position: relative;
			width: 100%;
			float: left;
			background-image: none;
			img {
				display: inline-block;
				position: relative;
				left: 50%;
				@include transform(translate(-50%,0));
				width: 100%;
				margin: 0;
				padding: 40px 10px;
			}
		}
		.media-section {
			width: 100%;
			float: left;
			padding: 40px 10px;
			.news {
				float: left;
				width: 100%;
				height: 300px;
				background-image: -ms-linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) );
			}
			.text {
				float: left;
				width: 100%;
				height: inherit;
				padding: 40px 20px;
				background-color: $orange;
				h3 {
	  				font-size: 22px;
	  				color: rgb(255, 255, 255);
	  				font-style: italic;
	  				text-align: center;
	  				padding: 10px;

				}
				p {
					font-size: 16px;
	  				color: rgb(255, 255, 255);
	  				text-align: center;
	  				font-style: italic;
				}
			}
			.portrait {
				float: left;
				width: 50%;
				height: 300px;
				background-size: cover;
				background-position: center center;
			}
			.landscape {
				float: left;
				width: 100%;
				height: 300px;
				background-size: cover;
				background-position: center center;
			}
		}
	}

}

@media screen and (max-width: 479px) {

}

