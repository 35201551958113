.ticketbox {
	.background {
		float: left;
		width: 100%;
		// margin: 50px 0;
  		background-color: $green;
		.frame {
			width: 100%;
			float: left;
			padding: 100px 100px 50px 100px;
			margin: 0 auto;
			.ticketfield-left,
			.ticketfield-right {
				width: 50%;
				margin-bottom: 50px;
				margin: auto;
				.inner {
					width: 100%;
					padding: 0 20px;
					.head {
						padding: 20px 30px;
						background-color: $lightgreen;
						.ticket-icon {
							display: block;
							float: left;
							margin-top: 5px;
				       	    // margin: 12px 20px 0 25px;
				            width: 24px;
				            height: 16px;
				            background-image: url(../images/icon-button-tickets-white.svg);
						}
						h3 {
							display: block;
							padding-left: 40px;
							// color: #fff;
						  	font-size: 24px;
						  	color: $darkgreen;
						  	line-height: 1.167;
						  	margin-bottom: 0;
						}
						p {
						  font-size: 16px;

						  color: rgb(255, 255, 255);
						  line-height: 1.75;
						}
					}
					.ticket-body {
						padding: 15px 30px 30px 30px;
						width: 100%;
						float: left;
						background-color: #fff;
						h4 {
							color: $darkgreen;
							font-size: 20px;
							color: rgb(74, 93, 97);
							line-height: 1.4;
						}
						ul {
							position: relative;
							padding-left: 40px;
							li {
								position: relative;
								  font-size: 16px;
								  color: rgb(51, 51, 51);
								  line-height: 20px;
								  padding-bottom: 10px;
							}
							li:after {
								position: absolute;
								left: -40px;
								top: 5px;
								content: '';
								width: 15px;
								height: 10px;
								background-image: url(../images/icon-bullet.svg);
								background-size: cover;

							}
						}
						hr {
							border: none;
							border-bottom: 1px solid $green;
							margin: 30px 0;
						}
						p {
							  font-size: 16px;
							  color: rgb(51, 51, 51);
							  line-height: 20px;
						}
					}
					.btn-ticket-purple {
				      	width: 100%;
						padding: 0 0 15px 0;
				      	border-radius: 2px;
				      	background-color: $lightgreen;
				      	position: relative;
			           top: -2px;
			           display: inline-block;
			           color: #fff;
				        .icon {
				           display: inline-block;
				       	   margin: 13px 20px 0 80px;
				           width: 24px;
				           height: 16px;
				           background-image: url(../images/icon-button-tickets-white.svg);
				        }

				    }
				}
			}
			h5 {
			  	font-size: 16px;

			  	color: rgb(74, 93, 97);
			  	text-align: center;
				margin: 0;
			}
		}
	}
}

.text-area {
	.inner {
		float: left;
		width: 100%;
		background-color: #fff;
		padding: 40px 0;
	}
	h2 {
	   	font-size: 20px;
	   	color: $green;
	   	text-align: center;
	   	padding-top: 20px;
	}
	p {
  		font-size: 20px;
  		line-height: 25px;
  		color: rgb(74, 93, 97);
  		text-align: center;
  		padding: 0 100px;
		padding-bottom: 20px;
	}
	a {
		font-size: 20px;

  		color: rgb(74, 93, 97);
  		text-align: center;
		text-decoration: underline;
	}
	ul {
		text-align: center;
	}
	li {
		position: relative;
		font-size: 20px;

  		color: rgb(74, 93, 97);
  		padding-left: 15px;
	}
	// li:before {
	// 	position: absolute;
	// 	content: '';
	// 	top: 0;
	// 	left: 0;
	// 	width: 5px;
	// 	height: 5px;
	// 	border-radius: 5px;
	// 	background-color: rgb(74, 93, 97);
	// }
	.quote {
		width: 70%;
		margin: 50px auto;
		padding: 20px 80px;
		background-color: $lightgreen;
		h3 {
  			font-size: 24px;

  			color: rgb(74, 93, 97);
  			text-align: center;
		}
		h6 {
		  font-size: 16px;

		  color: rgb(129, 157, 152);
		  text-align: center;
		  margin: 0;
		}
	}
}

.weather {
	// padding: 40px 0px;
	.canvas{
		background-color: #fff;
	}
}



@media screen and (max-width: 1200px) {
  .ticketbox {
  	.background {
  		.frame {
  			padding: 50px 20px;
  		}
  	}
  }
}

@media screen and (max-width: 900px) {
  .ticketbox {
  	.background {
  		.frame {
  			padding: 50px 20px;
  			.ticketfield-left,
  			.ticketfield-right {
  				width: 100%;
  			}
  		}
  	}
  }
}

@media screen and (max-width: 479px) {
  .ticketbox {
  	.background {
  		.frame {
  			padding: 50px 0px;
  			.ticketfield-left,
  			.ticketfield-right {
  				width: 100%;
  				.inner {
  					padding: 0 5px;
  				}
  			}
  		}
  	}
  }
  .ticketbox .background .frame .ticketfield-left .inner .btn-ticket-purple .icon,
  .ticketbox .background .frame .ticketfield-right .inner .btn-ticket-purple .icon {
	    display: inline-block;
	    margin: 13px 20px 0 40px;
	    width: 24px;
	    height: 16px;
	    background-image: url(../images/icon-button-tickets-white.svg);
	}
	.text-area {
	h2 {
	   	font-size: 20px;

	   	color: rgb(74, 93, 97);
	   	text-align: center;
	   	padding-top: 20px;
	}
	p {
  		font-size: 20px;

  		color: rgb(74, 93, 97);
  		text-align: center;
  		padding: 0 20px;
		padding-bottom: 20px;
	}
	a {
		font-size: 20px;

  		color: rgb(74, 93, 97);
  		text-align: center;
		text-decoration: underline;
	}
	ul {
		text-align: center;
	}
	li {
		position: relative;
		font-size: 20px;

  		color: rgb(74, 93, 97);
  		padding-left: 15px;
	}
	// li:before {
	// 	position: absolute;
	// 	content: '';
	// 	top: 0;
	// 	left: 0;
	// 	width: 5px;
	// 	height: 5px;
	// 	border-radius: 5px;
	// 	background-color: rgb(74, 93, 97);
	// }
	.quote {
		width: 90%;
		margin: 50px auto;
		padding: 20px;
		background-color: rgb(232, 241, 239);
		h3 {
  			font-size: 24px;

  			color: rgb(74, 93, 97);
  			text-align: center;
		}
		h6 {
		  font-size: 16px;

		  color: rgb(129, 157, 152);
		  text-align: center;
		  margin: 0;
		}
	}
}
}


