.banner-top {
  float: left;
  width: 100%;
  background-color: #fff;
  p {
    padding: 15px;
    font-size: 16px;
    font-family: 'Open Sans', serif;
    font-weight: 600;
    color: $orange;
    text-transform: uppercase;
    text-align: center;
  }
}

header {
  position: relative;
  float: left;
  width: 100%;
  overflow-x: hidden;
  background-color: $gray;

  .header {
    width: 100%;
    background-color: $orange;
    height: 190px;
  }

  .container {
    height: 100%;
    .facebook {
      display: block;
      position: absolute;
      top: 20px;
      left: 20px;
      width: 10px;
      height: 20px;
      background-image: url(../images/icon-facebook.svg);
      background-repeat: no-repeat;
      cursor: pointer;
      color: $orange;
      p {
        display: inline-block;
        padding-left: 25px;
        padding-top: 5px;
        font-size: 14px;
        color: $white;
      }
    }
    .facebook:hover{
      opacity: 0.5;
    }
    .logo-frame:hover {
      opacity: 0.7;
    }
    .logo-frame {
      display: block;
      position: absolute;
      width: 233px;
      height: 123px;
      left: 50%;
      @include transform(translate(-50%,0%));
      .logo {
        position: relative;
        width: 100%;
        height: 100%;
        background-image: url(../images/logo-zomer-fair-soestdijk.svg);
        background-repeat: no-repeat;
        background-position: center center;
      }
      .logo:after {
        content: '';
        position: absolute;
        right: 100%;
        width: 450px;
        bottom: 0;
        height: 10px;
        border-bottom: 1px solid white;
        opacity: 0.2;
      }
      .logo:before {
        content: '';
        position: absolute;
        left: 100%;
        width: 450px;
        bottom: 0;
        height: 10px;
        border-bottom: 1px solid white;
        opacity: 0.2;
      }
    }
    .btn-ticket-white:hover {
      opacity: 0.7;
    }
    .btn-ticket-white {
      display: block;
      position: absolute;
      right: 0;
      margin-top: 20px;
      margin-right: 40px;
      width: 180px;
      height: 40px;
      border-radius: 2px;
      background-color: #fff;
      box-shadow: 0 2px 2px $orange-dark;
      .icon {
        display: inline-block;
        margin: 12px 20px 0 25px;
        width: 24px;
        height: 16px;
        color: #fff;
        background-image: url(../images/icon-button-tickets.svg);
      }
      p {
        position: relative;
        top: -2px;
        display: inline-block;
        color: #e0581e;
        font-weight: bold;
      }
    }
    .menu-desktop {
        ul {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 60px;
        text-align: center;
        li {
          display: inline-block;
          // width: 16%;
          padding: 0 16px;
          text-align: center;
          padding-top: 20px;
          a {
            position: relative;
            font-size: 16px;
            color: #fff;
            border-bottom: 2px solid transparent;
          }
          a:hover {
            border-bottom: 2px solid $white;
          }
        }
        .current-menu-item a {
          border-bottom: 2px solid $white;
        }
      }
    }
  }
}

.menu-mobile {
  display: none;
  position: absolute;
  top: 140px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $orange;
  z-index: 9999;
  overflow-y: hidden;
  ul {
    padding-top: 20px;
    width: 100%;
    li {
      text-align: center;
      padding: 10px 0;
      a {
        position: relative;
        font-size: 20px;
        color: rgb(255, 255, 255);
      }
      a:hover:before {
            position: absolute;
          content: '';
          top: 5px;
          left: -20px;
          width: 10px;
          height: 20px;
          background-image: url(../images/icon-menu-active.svg);
          background-repeat: no-repeat;
          }
      }
    .current-menu-item a:before {
      position: absolute;
      content: '';
      top: 5px;
      left: -20px;
      width: 10px;
      height: 20px;
      background-image: url(../images/icon-menu-active.svg);
      background-repeat: no-repeat;
    }
  }
}




@media screen and (max-width: 1150px) {

  .menu-desktop {
    display: none;
  }
  header .container .btn-ticket-white  {
    display: none;
  }
  header .header {
    height: 90px;
  }

  header .container .logo-frame {
    width: 140px;
    height: 62px;
  }

  .nav-toggle {
    position: absolute;
    top: 13px;
    right: 10px;
    z-index: 100;
    width: 50px;
    height: 50px;
    padding: 15px 10px 15px 10px;
    margin: 5px;
    cursor: pointer;
  }

  .nav-toggle .bar {
    position: absolute;
    left: 10px;
    height: 3px;
    width: 26px;
    background: white;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }

  .nav-toggle .bar:nth-child(1) { top: 14px; }
  .nav-toggle .bar:nth-child(2) { top: 23px; }
  .nav-toggle .bar:nth-child(3) { top: 32px; }

  .nav-toggle.open .bar:nth-child(1) {
    top: 24px;
    -webkit-transform:rotate(-45deg);
    -moz-transform:rotate(-45deg);
    transform:rotate(-45deg);
  }

  .nav-toggle.open .bar:nth-child(2) {
    opacity: 0;
  }

  .nav-toggle.open .bar:nth-child(3) {
    top: 24px;
    -webkit-transform:rotate(45deg);
    -moz-transform:rotate(45deg);
    transform:rotate(45deg);
  }
}



@media screen and (max-width: 574px) {
    .menu-mobile {
      top: 160px;
    }
}

@media screen and (max-width: 767px) {
  .btn-ticket-white {
    display: none;
  }
}

@media screen and (max-width: 479px) {

}

