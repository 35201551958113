a:hover{
	opacity: 0.7;
}

.sub-header-home {
	.container {
	}
	.left {
		position: relative;
		float: left;
		width: 65%;
		height: 600px;
		background-size: cover;
		background-position: center center;
		.btn-action-anchor:hover {
			display: block;
			opacity: 0.7;
		}
		.btn-action {
			display: block;
			position: absolute;
			left: 15px;
			top: -10px;
			width: 150px;
			height: 150px;
			border-radius: 150px;
			background-color: $white;
			a {
				display: block;
				padding-top: 23px;
				padding-left: 0px;
				width: 100%;
				font-family: 'Muli', serif;
				font-weight: normal;
				font-size:18px;
				color: $orange-dark;
				letter-spacing: -0.5px;
				line-height: 25px;
				text-align: center;
			}
			span {
				font-size:30px;
			}
		}
		.textbox {
			position: absolute;
			left: 0;
			bottom: 0;
			padding: 40px 40px;
			background-color: #fff;
			opacity: 0.7;
			h1 {
				font-size: 31px;
				font-family: 'Muli', serif;
				letter-spacing: 6px;
  				color: rgb(28, 28, 28);
  				text-transform: uppercase;
  				line-height: 30px;
  				margin: 0px;
  				text-align: left !important;
  				border-bottom: none;
  				margin-bottom: 5px;
			}
			p {
  				font-size: 16px;
  				color: rgb(28, 28, 28);
  				text-align: left;
  				margin-bottom: 5px;
			}
		}
	}
	.right:hover {
		opacity: 0.8;
	}
	.right {
		float: left;
		width: 35%;
		height: 300px;
		background-size: cover;
		background-position: center center;
		.gradient {
			float: left;
			width: 100%;
			height: 100%;
			background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
			background-image: -o-linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
			background-image: -ms-linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
			background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3));
		}
		h2 {
			padding: 20px;
  			font-size: 26px;

			color: rgb(255, 255, 255);
		}
		a {
			display: inline-block;
			padding: 10px 20px;
			margin-left: 20px;
			background-color: $green;
			border-radius: 2px;
  			font-size: 16px;
  			color: rgb(255, 255, 255);
  			span {
  				display: inline-block;
  				margin-left: 5px;
  				width: 6px;
  				height: 9px;
  				background-image: url(../images/icon-crown.svg);
  				background-size: cover;
  				background-position: center center;
  			}
		}
	}
}

.text-wide {
	.crown {
		position: absolute;
		bottom: 2px;
		right: -65px;
		width: 130px;
		height: 70px;
		background-image: url(../images/icon-crown.svg);
		background-size: cover;
	}
	.container {
	}
	.textbox {
		position: relative;
		float: left;
		width: 100%;
		padding: 40px 400px 40px 40px;
		background-color: $orange;
		h2 {
			font-size: 26px;

			color: $white;
		}
		p {
  			font-size: 18px;

  			color: $white;
  			line-height: 25px;
		}
		.btn-purple-home {
			display: block;
			position: absolute;
			right: 200px;
			top: -20px;
			width: 150px;
			height: 150px;
			border-radius: 150px;
			background-color: $white;
			padding-top: 38px;
			padding-left: 0px;
			font-family: 'Muli', serif;
			font-weight: 600;
			color: $orange;
			line-height: 20px;
			text-align: center;
  			font-size: 20px;
  			line-height: 25px;
		}
	}
}


.grid {
	// .frame {
	// 	float: left;
	// 	width: 100%;
	// }
	.frame {
		float: left;
		width: 100%;
		display: -webkit-flex;
	    display: -ms-flexbox;
	    display: flex;

	    -webkit-flex-wrap: stretch;
	    -ms-flex-wrap: stretch;
	    flex-wrap: stretch;
	}
	.news-feed {
		float: left;
		width: 33.3333333333%;
		padding: 40px;
		background-color: #fff;
		h3 {
			font-size: 14px;
			text-transform: uppercase;
			font-family: 'Muli';
			color: $green;
			margin: 0 0 20px;
		}
		h4 {
		  font-size: 20px;

		  color: rgb(51, 51, 51);
		  line-height: 25px;
		}
		h5 {
		  font-size: 12px;

		  color: rgb(200, 200, 200);
		}
		p {
		  font-size: 16px;
		  font-weight: 400;
		  line-height: 20px;
		  color: rgb(51, 51, 51);
		}
		a {
			font-size: 16px;
			color: $green;
			display: block;
			padding-bottom: 20px;
			padding-top: 10px;
			span {
  				display: inline-block;
  				margin-left: 5px;
  				width: 6px;
  				height: 9px;
  				background-image: url(../images/icon-chevron-right.svg);
  				background-size: cover;
  				background-position: center center;
  				background-repeat: no-repeat;
  			}
		}
		.btn-green {
			display: inline-block;
			margin-bottom: 40px;
			padding: 10px 20px;
			margin-top: 0px;
			background-color: $green;
			box-shadow: 0 2px 2px $darkgreen;
			border-radius: 2px;
  			font-size: 16px;
  			color: $white;
		}
	}
	.read-more:hover {
		opacity: 0.8;
	}
	.read-more {
		float: left;
		width: 33.3333333333%;
		background-size: cover;
		background-position: center center;
		.gradient {
			float: left;
			width: 100%;
			height: 100%;
			background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
			background-image: -o-linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
			background-image: -ms-linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
			background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
		}
		h2 {
			padding: 20px;
  			font-size: 26px;
			color: rgb(255, 255, 255);
		}
		a {
			padding: 10px 20px;
			margin-left: 20px;
			background-color: $green;

			border-radius: 2px;
  			font-size: 16px;
  			color: rgb(255, 255, 255);
  			span {
  				display: inline-block;
  				margin-left: 5px;
  				width: 6px;
  				height: 9px;
  				background-image: url(../images/icon-chevron-right.svg);
  				background-size: cover;
  				background-position: center center;
  			}
		}
	}
	hr {
		border: none;
		border-bottom: 1px solid gray;
	}
	.transport,
	.info-stands  {
		position: relative;
		float: left;
		width: 33.3333333333%;
		padding: 0px;
		background-color: #fff;
		h3 {
			padding: 40px 20px 0 40px;
			font-size: 14px;
			font-family: 'Muli', serif;
			color: $green;
			margin: 0;
			text-transform: uppercase;
		}
		h4 {
		  font-size: 20px;
		  font-weight: 400;
		  color: rgb(51, 51, 51);
		  line-height: 25px;
		  padding: 20px 20px 0 40px;
		  padding-bottom: 20px;
		}
		a {
			display: inline-block;
			margin-bottom: 40px;
			padding: 10px 20px;
			margin-top: 0px;
			background-color: $green;
			box-shadow: 0 2px 2px $darkgreen;
			border-radius: 2px;
  			font-size: 16px;
  			color: rgb(255, 255, 255);
  			margin: 0 20px 40px 40px;
  			span {
  				display: inline-block;
  				margin-left: 5px;
  				width: 6px;
  				height: 9px;
  				background-image: url(../images/icon-chevron-right.svg);
  				background-size: cover;
  				background-position: center center;
  			}
		}
		hr {
			border: none;
			border-bottom: 1px dashed $green;
			margin-bottom: 0px;
		}
	}
}


/* ==========================================================================
   media styles
   ========================================================================== */


@media screen and (max-width: 959px) {

}

@media screen and (max-width: 767px) {
	.sub-header-home {
		.left {
			width: 100%;
		}
		.right {
			width: 100%;
		}
	}
	.text-wide {
		.textbox {
			padding: 40px;
			.btn-purple-home {
				right: 20px;
				top: -100px;
			}
		}
	}
	.grid {
		.frame {
			display: block;
			.news-feed {
				width: 100%;
			}
			.read-more{
				width: 100%;
				height: 300px;
			}
			.transport {
				width: 100%;
			}
		}
	}

}

@media screen and (max-width: 479px) {
  	.text-wide {
		.textbox {
			.btn-purple-home {
				display: none;
			}
		}
	}
}











