.news-items {
	.inner {
		float: left;
		width: 100%;
		background-color: #fff;
	}
	h1 {
		position: relative;
		left: 50%;
		@include transform(translate(-50%,0));
		display: inline-block;
		text-align: center;
		margin-bottom: 50px;
		margin-top: 30px;
	  	font-size: 30px;
	  	font-family: 'Muli', serif;
	  	color: $green;
	  	text-transform: uppercase;
	  	border-bottom: 1px solid;
	}
	.news-cell {
		width: 100%;
		float: left;
		padding: 20px 100px 20px 100px;
		background-color: #fff;
		hr {
			float: left;
			width: 100%;
			margin-top: 40px;
			border: none;
			border-bottom: 1px solid $green;
		}
		.img-left {
			float: left;
			display: inline-block;
			width: 30%;
			padding-bottom: 20%;
			background-size: cover;
			background-position: center center;
		}
		.img-left2 {
			float: left;
			display: inline-block;
			width: 30%;
			background-size: cover;
			background-position: center center;
			img {
				margin: 20px;
				width: 200px;
			}
		}
		.text-right {
			float: left;
			width: 70%;
			padding-left: 20px;
			h3 {
				padding-bottom: 0px;
				font-size: 20px;
				color: rgb(74, 93, 97);
			}
			h4 {
			  font-size: 12px;
			  color: rgb(200, 200, 200);
			}
			p {
			  font-size: 16px;
			  color: rgb(51, 51, 51);
			  line-height: 20px;
			  padding-bottom: 30px;
			}
			a {
				padding: 8px 20px 10px;
				margin-top: 20px;
				background-color: $green;
				box-shadow: 0 2px 2px $darkgreen;
				border-radius: 2px;
	  			font-size: 16px;
	  			color: rgb(255, 255, 255);
	  			span {
	  				display: inline-block;
	  				margin-left: 5px;
	  				width: 6px;
	  				height: 9px;
	  				background-image: url(../images/icon-chevron-right.svg);
	  				background-size: cover;
	  				background-position: center center;
	  			}
			}
		}
	}
	.pagination {
		margin-top: 20px;
		width: 100%;
		float: left;
		padding-left: 100px;
		padding-bottom: 20px;
		a {
			padding: 10px 18px;
			color: $darkgreen;
			border: 1px solid $green;
			font-size: 22px;
			font-family: 'Muli', serif;
			font-weight: 600;
		}
		span {
			padding: 10px 20px;
			color: #fff;
			border: 1px solid $darkgreen;
			background-color: $darkgreen;
			font-size: 22px;
			font-family: 'Muli', serif;
		}
	}
}


@media screen and (max-width: 959px) {

}

@media screen and (max-width: 767px) {
	.news-items {
		.news-cell {
			.img-left {
				width: 100%;
				padding-bottom: 40%;
				margin-bottom: 20px;
			}
			.text-right {
				width: 100%;
			}
		}
	}
}

@media screen and (max-width: 479px) {
  	.news-items .news-cell {
	    width: 100%;
	    float: left;
	    padding: 20px;
	}
	.news-items .pagination {
		padding: 20px;
	}
}


