.stands {
	padding-top: 0px;
	.frame {
		position: relative;
		width: 100%;
		float: left;
		padding: 80px 0;
		background-color: #e8f1ef;
		.btn-purple {
			display: block;
			position: absolute;
			right: 20px;
			top: -100px;
			width: 150px;
			height: 150px;
			border-radius: 150px;
			background-color: $orange;
			padding-top: 38px;
			padding-left: 0px;
			font-family: 'Muli', serif;
			font-weight: 600;
			color: #fff;
			line-height: 20px;
			text-align: center;
  			font-size: 20px;
  			line-height: 25px;
		}
		.stands-list {
			margin: 0 auto;
			width: 90%;
			max-width: 850px;
			.stands-filter {
				position: relative;
				float: left;
				width: 100%;
				padding: 10px 30px;
				margin-bottom: 10px;
				background-color: #fff;
				.left {
					width: 20%;
					float: left;
					p {
						margin-top: 18px;
  						font-size: 16px;
  						color: $darkgreen;
					}
				}
				.right {
					width: 80%;
					float: right;
					.filter-box {
						float: left;
						padding: 10px;
						border: 1px solid $lightgreen;
						color: $green;
						margin: 5px;
						cursor: pointer;
						p {
							  font-size: 18px;
							  font-family: 'Muli', serif;
							  font-weight: 600;
							  color: $darkgreen;
						}
						&.active {
							background-color: $lightgreen;
							padding-right: 30px;
							background-image: url(../images/cross.png);
							background-size: 15px 15px;
							background-position: right 8px center;
							background-repeat: no-repeat;
						}
					}
				}
			}
			.stands-cell {
				position: relative;
				float: left;
				width: 100%;
				padding: 0px 10px;
				background-color: #fff;
				margin-bottom: 5px;
				.logo-canvas {
					position: relative;
					float: left;
					width: 200px;
					height: 200px;
					img {
						position: absolute;
						left: 50%;
						top: 50%;
						@include transform(translate(-50%,-50%));
						max-width: 150px;
					}
				}
				.textbox {
					float: left;
					padding-top: 40px;

					h3 {
  						font-size: 20px;
  						color: rgb(51, 51, 51);
					}
					a {
						display: block;
						width: 100%;
						float: left;
  						font-size: 16px;
  						color: rgb(51, 51, 51);
					}
					.icon {
						margin-top: 20px;
						float: left;
						width: 25px;
						height: 25px;
						background-image: url(../images/icon-label.svg);
						background-size: cover;
					}
					h4 {

					  font-size: 18px;
					  font-family: 'Muli', serif;
					  color: $green;
					  display: inline-block;
					  padding-left: 15px;
					  padding-top: 15px;
					}
				}
			}
			.tab-pagination {
				margin-top: 40px;
				width: 100%;
				float: left;
				text-align: center;
				.tab {
						display: inline-block;
						margin: 5px;
						padding: 10px 18px;
						color: $darkgreen;
						border: 1px solid $green;
						font-size: 18px;
						font-family: 'Muli', serif;
						font-weight: 600;
						cursor: pointer;
				&.active {
						padding: 10px 20px;
						color: #fff;
						border: 1px solid $darkgreen;
						background-color: $darkgreen;
						font-size: 18px;
						font-family: 'Muli', serif;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 600px) {
  	.stands .frame .stands-list .stands-cell {
  		.logo-canvas {
  			height: 150px;
  		}
  		.textbox {
	    float: left;
	    padding-top: 0px;
	    padding-left: 40px;
	    padding-bottom: 40px;
	   }
	}
	body.page-id-11 {
		.text-top {
			p {
				padding-bottom: 80px;
			}
		}
	}
	.stands .frame .stands-list .stands-filter {
		.left {
			width: 100%;
			p {
				margin-bottom: 10px;
				margin-left: 5px;
			}
		}
		.right {
		width: 100%;
		}
	}
}
